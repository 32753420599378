<template>
    <v-container>
        <v-form ref="login" @submit.prevent="login" v-if="!useSSO">
            <v-card color="accent" dark max-width="500" class="mx-auto">
                <v-card-title class="font-weight-light">QC Player Login</v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="username"
                        label="Username"
                        autocomplete="username"
                        color="white"
                        outlined
                        hide-details
                        class="mb-5"
                    ></v-text-field>

                    <v-text-field
                        v-model="password"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        label="Password"
                        autocomplete="current-password"
                        color="white"
                        outlined
                        hide-details
                        @click:append="showPassword = !showPassword"
                    ></v-text-field>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn
                        color="accent2"
                        type="submit"
                    >
                        Log In
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
        <div class="d-flex justify-center mt-10" v-else>
            <!-- <v-progress-circular indeterminate color="accent2" size="128"></v-progress-circular> -->
            <v-img src="@/assets/stella_large.gif" contain max-width="250"></v-img>
        </div>
    </v-container>
</template>

<script>
    export default {
        data: () => ({
            username: null,
            password: null,
            showPassword: false,
            useSSO: window.__env.useSSO,
            ssoServer: window.__env.ssoURL,
            token: null,
            redirect: null,
        }),

        methods: {
            // async login() {
            //     try {
            //         const response = await fetch('/api/login/password', {
            //             method: 'POST',
            //             credentials: 'same-origin', // include, *same-origin, omit
            //             headers: {
            //                 'Content-Type': 'application/json'
            //             },
            //             body: JSON.stringify({
            //                 username: this.username,
            //                 password: this.password,
            //             }),
            //         });

            //         console.log(await response.json());

            //         //@todo check username and password
            //         if (this.username === 'rdunajewski') {
            //             //commit a mutation to make us logged in
            //             this.$store.commit('login');

            //             //redirect to homepage (or elsewhere, later on)
            //             this.$router.push({name: 'Home'}).catch(() => {
            //             });
            //         } else {
            //             //error
            //             console.log('Error with login');
            //         }
            //     } catch(err) {
            //         console.error('Error with login');
            //         console.error(err);
            //     }

            //     // if(this.username === 'mdsuser' && this.password === 'mdsuser') {
            //     //     //commit a mutation to make us logged in
            //     //     this.$store.commit('login');
            //     //
            //     //     //redirect to homepage (or elsewhere, later on)
            //     //     this.$router.push({name: 'Home'}).catch(() => {
            //     //     });
            //     // }else{
            //     //     //error
            //     //     console.log('Error with login')
            //     // }
            // },
        },

        computed: {
            ssoURL() {
                return (this.ssoServer.https ? 'https://' : 'http://') + this.ssoServer.host + (!this.ssoServer.https && this.ssoServer.port !== 80 || this.ssoServer.https && this.ssoServer.port !== 443 ? ':' + this.ssoServer.port : '') + this.ssoServer.path;
            },
        },

        mounted() {
            this.token = this.$route.query.token;
            this.redirect = this.$route.query.redirect;
            this.action = this.$route.query.action;

            if (this.useSSO) {
                if (!this.token) {
                    const queryParams = new URLSearchParams();

                    if (this.action) queryParams.append('action', this.action);
                    // if (this.redirect) queryParams.append('return', encodeURIComponent(this.redirect));
                    if (this.redirect) queryParams.append('return', this.redirect);

                    window.location = `${this.ssoURL}${Array.from(queryParams).length > 0 ? `?${queryParams.toString()}` : ''}`;
                } else {
                    this.$store.commit('login');
                    if (this.redirect) {
                        window.location = this.redirect;
                    } else {                    
                        this.$router.push({name: 'Home'});
                    }
                }
            }
        }
    }
</script>